import React, { useState } from "react"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { FormattedMessage } from "react-intl"
import '../style/uqbar.scoped.scss'
import Icon from '../components/iconfontCpt'
import { Link } from "gatsby"
import { Tabs } from 'antd'

const Uqbar = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''

  const [activeType, setActiveType] = useState(1) // 1 2 3 4 分别代表四个客户价值
  const changePage = type => {
    let curType = activeType
    if (type === 'next') {
      curType = curType + 1
    } else {
      curType = curType - 1
    }
    setActiveType(curType)
  }
  return (
    <Layout pageLang={ pageLang } showHeader={true} showFooter={true}>
    <Seo title="MogDB: Uqbar"/>
      <div className="page-container">
        <div className="uqbar-banner">
          <div className="product-banner-box black-thema">
            <h1 className="product-banner-title"><FormattedMessage id="uqbar.pageTitle" /><i className="uqbar"></i></h1>
            <div className="product-banner-desc">
              <p><FormattedMessage id="uqbar.pageContent" /></p>
            </div>
            <div className="btn-group">
              <Link to={`${pagePrefix}/downloads/uqbar/`} className="btn download"><FormattedMessage id="index.download" /></Link>
              <a href={`https://docs.mogdb.io/${pageLang}/uqbar/latest/overview`} rel="noreferrer" target="_blank" className="btn searchDoc"><FormattedMessage id="index.docs" /></a>
            </div>
          </div>
        </div>
        <div className="uqbar-value carousel-box">
          <div className="carousel-item-box">
            <div className={`carousel-item-title-box ${activeType === 1 ? 'pc-show': ''}`}>
              <h3 className="carousel-item-title"><FormattedMessage id="uqbar.valueTitle1" /></h3>
              <h3 className="carousel-item-desc"><FormattedMessage id="uqbar.valueDesc1" /></h3>
            </div>
            <div className={`carousel item1 ${activeType === 1 ? 'pc-show': ''}`}>
              <div className="item-box item1-box">
                <div className="carousel-icon"></div>
                <div className="title"><FormattedMessage id="uqbar.valueTitle1-1" /></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc1-1" /></div>
              </div>
              <div className="item-box item1-box">
                <div className="carousel-icon"></div>
                <div className="title"><FormattedMessage id="uqbar.valueTitle1-2" /></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc1-2" /></div>
              </div>
            </div>
            <div className={`carousel-item-title-box ${activeType === 2 ? 'pc-show': ''}`}>
              <h3 className="carousel-item-title"><FormattedMessage id="uqbar.valueTitle2" /></h3>
              <h3 className="carousel-item-desc"><FormattedMessage id="uqbar.valueDesc2" /></h3>
            </div>
            <div className={`carousel item2 ${activeType === 2 ? 'pc-show': ''}`}>
              <div className="item-box item2-box flex2">
                <div className="title-box"><div className="carousel-icon"></div><div className="title"><FormattedMessage id="uqbar.valueTitle2-1" /></div></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc2-1" /></div>
              </div>
              <div className="item-box item2-box flex2">
                <div className="title-box"><div className="carousel-icon"></div><div className="title"><FormattedMessage id="uqbar.valueTitle2-2" /></div></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc2-2" /></div>
              </div>
              <div className="item-box item2-box">
                <div className="title-box"><div className="carousel-icon"></div><div className="title"><FormattedMessage id="uqbar.valueTitle2-3" /></div></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc2-3" /></div>
              </div>
              <div className="item-box item2-box">
                <div className="title-box"><div className="carousel-icon"></div><div className="title"><FormattedMessage id="uqbar.valueTitle2-4" /></div></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc2-4" /></div>
              </div>
              <div className="item-box item2-box">
                <div className="title-box"><div className="carousel-icon"></div><div className="title"><FormattedMessage id="uqbar.valueTitle2-5" /></div></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc2-5" /></div>
              </div>
            </div>
            <div className={`carousel-item-title-box ${activeType === 3 ? 'pc-show': ''}`}>
              <h3 className="carousel-item-title"><FormattedMessage id="uqbar.valueTitle3" /></h3>
              <h3 className="carousel-item-desc"><FormattedMessage id="uqbar.valueDesc3" /></h3>
            </div>
            <div className={`carousel item3 ${activeType === 3 ? 'pc-show': ''}`}>
              <div className="item-box item3-box">
                <div className="carousel-icon"></div>
                <div className="title"><FormattedMessage id="uqbar.valueTitle3-1" /></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc3-1" /></div>
              </div>
              <div className="item-box item3-box">
                <div className="carousel-icon"></div>
                <div className="title"><FormattedMessage id="uqbar.valueTitle3-2" /></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc3-2" /></div>
              </div>
              <div className="item-box item3-box">
                <div className="carousel-icon"></div>
                <div className="title"><FormattedMessage id="uqbar.valueTitle3-3" /></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc3-3" /></div>
              </div>
            </div>
            <div className={`carousel-item-title-box ${activeType === 4 ? 'pc-show': ''}`}>
              <h3 className="carousel-item-title"><FormattedMessage id="uqbar.valueTitle4" /></h3>
              <h3 className="carousel-item-desc"><FormattedMessage id="uqbar.valueDesc4" /></h3>
            </div>
            <div className={`carousel item4 ${activeType === 4 ? 'pc-show': ''}`}>
              <div className="item-box item4-box">
                <div className="title-box"><div className="carousel-icon"></div><div className="title"><FormattedMessage id="uqbar.valueTitle4-1" /></div></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc4-1" /></div>
              </div>
              <div className="item-box item4-box">
                <div className="title-box"><div className="carousel-icon"></div><div className="title"><FormattedMessage id="uqbar.valueTitle4-2" /></div></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc4-2" /></div>
              </div>
              <div className="item-box item4-box">
                <div className="title-box"><div className="carousel-icon"></div><div className="title"><FormattedMessage id="uqbar.valueTitle4-3" /></div></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc4-3" /></div>
              </div>
              <div className="item-box item4-box">
                <div className="title-box"><div className="carousel-icon"></div><div className="title"><FormattedMessage id="uqbar.valueTitle4-4" /></div></div>
                <div className="desc"><FormattedMessage id="uqbar.valueDesc4-4" /></div>
              </div>
            </div>
          </div>
          <div className="carousel-btn-group">
            <div className={`btn left ${activeType <= 1 ? 'disabled' : ''}`} onClick={() => changePage('prev')}></div>
            <div className={`btn right ${activeType >= 4 ? 'disabled' : ''}`} onClick={() => changePage('next')}></div>
          </div>
        </div>
        <div className="product-theme-title">
          <FormattedMessage id="uqbar.sceneTitle" />
        </div>
        <div className="scene-container">
          <div className="scene-box">
            <h3 className="scene-title"><FormattedMessage id="uqbar.sceneTitle1" /></h3>
            <p className="scene-des"><FormattedMessage id="uqbar.sceneDes1" /></p>
          </div>
          <div className="scene-box">
            <h3 className="scene-title"><FormattedMessage id="uqbar.sceneTitle2" /></h3>
            <p className="scene-des"><FormattedMessage id="uqbar.sceneDes2" /></p>
          </div>
        </div>
      </div>
      <div className="experience">
        <div>
          <div className="e-title"><FormattedMessage id="index.experience" /></div>
          <div className="e-content"><FormattedMessage id="uqbar.experienceContent" /></div>
          <div className="btn-group">
            <a href={`https://docs.mogdb.io/${pageLang}/uqbar/latest/ptk-based-installation`} target="_blank" className="btn"><FormattedMessage id="index.useNow" /></a>
            <Link to={`${pagePrefix}/contact/`} className="btn download"><FormattedMessage id="index.contactUs" /></Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Uqbar